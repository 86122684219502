import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/": [16],
		"/(authenticated)/account": [24,[2,10]],
		"/(authenticated)/account/[tab]": [25,[2,10,11]],
		"/confirm": [31],
		"/(authenticated)/dashboard": [26,[2]],
		"/(authenticated)/experimental": [27,[2,12]],
		"/(authenticated)/experimental/agents": [28,[2,12,13]],
		"/(authenticated)/experimental/agents/[tab]": [29,[2,12,13]],
		"/(authenticated)/experimental/hewsync": [30,[2,12,14]],
		"/forgot-password": [32],
		"/iframe": [33],
		"/login-as": [34],
		"/logout": [35],
		"/reset-password": [36],
		"/share": [37,[],[15]],
		"/sign-up": [38],
		"/(authenticated)/[organization=organization]": [17,[2,3]],
		"/(authenticated)/[organization=organization]/api": [20,[2,3,6]],
		"/(authenticated)/[organization=organization]/api/[kind]/[id]/[[tab]]": [21,[2,3,6,7,8]],
		"/(authenticated)/[organization=organization]/dashboard": [22,[2,3]],
		"/(authenticated)/[organization=organization]/project/[id]/[...page]": [23,[2,3,9]],
		"/(authenticated)/[organization=organization]/[slug=perms]": [18,[2,3,4]],
		"/(authenticated)/[organization=organization]/[slug=perms]/[id]/[[tab]]": [19,[2,3,4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';